var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ozi-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"persistent":"healthProfessionalListTable"},on:{"click:row":_vm.handleView},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.handleCreate.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$tc('label.create'))+" ")],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.name ? _vm.formatName(item.name) : undefined)+" ")]),_c('div',{staticClass:"health_profession_name"},[_vm._v(" "+_vm._s(item.health_profession_name)+" ")])]}},{key:"item.service_providers.name_short",fn:function(ref){
var item = ref.item;
return _vm._l((item.service_providers),function(service_provider){return _c('v-chip',{key:service_provider.key,staticClass:"mx-1 my-1",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$langobj.parse(service_provider.name_short))+" ")])})}},{key:"item.points_of_service.address",fn:function(ref){
var item = ref.item;
return _vm._l((item.points_of_service),function(point_of_service){return _c('v-chip',{key:point_of_service.key,staticClass:"mx-1 my-1",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$langobj.parse(point_of_service.name))+" ")])})}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t('healthProfessional.status.' + item.status))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.handleEdit(item)}}},[_vm._v("mdi-pencil")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }