
























































import Vue from 'vue';
import HealthProfessionalUtils, {
  HealthProfessional,
} from '@/utils/HealthProfessionalUtils';
import { DataTableHeader } from 'vuetify';
import CommonUtils from '@/utils/CommonUtils';

export default Vue.extend({
  computed: {
    headers(): DataTableHeader<HealthProfessional>[] {
      return [
        {
          text: this.$tc('healthProfessional.name.label'),
          value: 'name',
          filter: (value: any, search: string | null, item: any) => {
            return (
              CommonUtils.clean(this.formatName(item.name))?.includes(
                search || ''
              ) ||
              CommonUtils.clean(item.health_profession_name)?.includes(
                search || ''
              ) ||
              false
            );
          },
          sort: (a: any, b: any): -1 | 0 | 1 => {
            const aName = CommonUtils.clean(this.formatName(a.name)) || '';
            const bName = CommonUtils.clean(this.formatName(b.name)) || '';
            return aName == bName ? 0 : aName > bName ? 1 : -1;
          },
        },
        {
          text: this.$tc('healthProfessional.job_title'),
          value: 'job_title',
        },
        {
          text: this.$tc('healthProfessional.division'),
          value: 'division',
        },
        {
          text: this.$tc('pointofservice.label', 2),
          value: 'points_of_service.address',
        },
        {
          text: this.$tc('healthProfessional.status.label'),
          value: 'status',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },

  data: (): {
    items?: HealthProfessional[];
  } => ({
    items: undefined,
  }),

  async mounted() {
    try {
      this.items = await HealthProfessionalUtils.api.list();
    } catch (error) {
      console.error(error);
    } finally {
      //
    }
  },

  methods: {
    async handleView(item: HealthProfessional) {
      await this.$router.push({
        name: 'health-professional-view',
        params: { id: item.id.toString() },
      });
    },
    async handleEdit(item: HealthProfessional) {
      await this.$router.push({
        name: 'health-professional-edit',
        params: { id: item.id.toString() },
      });
    },

    async handleCreate() {
      await this.$router.push({
        name: 'health-professional-create',
      });
    },

    formatName(name: HealthProfessional['name']) {
      return HealthProfessionalUtils.healthProfessional.formatName(name);
    },
  },
});
